import { default as accessibilitezkveJLUVVtMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/accessibilite.vue?macro=true";
import { default as _91slug_93jXX2K2QW3PMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/activites/[slug].vue?macro=true";
import { default as editas5J2ppMDNMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as indexjrkzdnArYLMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addG623JslFyBMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/activites/add.vue?macro=true";
import { default as indexbghOv0yM2oMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/activites/index.vue?macro=true";
import { default as edit6sUC1urzxQMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as addVLxSbQuM0oMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as indexQ3PJXG59muMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as editOTuLbGwN3HMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as addJYz9jSnKUCMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as indexNHO4uf0qkFMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as postsSR5cM7n7H6Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/posts.vue?macro=true";
import { default as edit4tGCpGdy3iMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexpf8Gmw4L8UMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as addaSagh7uKDgMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as indexokO1sW5wbGMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editclwZOpSwOhMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addC3oCO0Lq2LMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indexhSqaypmSZyMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as editc3NL5rzSMuMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as indexL6ewVOexWlMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as addKsWG03rmDzMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indexvynx36JmYMMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as edit1wZYhfshI5Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as indexSCNbkynNtzMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as indexw2hFeKb8s6Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/index.vue?macro=true";
import { default as benevoles_45informationsODXAU75pzCMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevolesMXkbIS9QkDMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as datesjIVDI1wbkxMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as index0PKDBasV9xMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informationsRlFvwPYaR0Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieuxkxQgxMkNEXMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsablesPeuTv8TyjZMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as title56OSo03WEWMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/title.vue?macro=true";
import { default as indexZNPI679C1iMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/trouver-des-benevoles/index.vue?macro=true";
import { default as redirect5ZlAm2N4FIMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/trouver-des-benevoles/redirect.vue?macro=true";
import { default as visuelkR2pCIMdeTMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as indexBnpDWbS4IMMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/index.vue?macro=true";
import { default as notesSRhhGBQd0SMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/notes.vue?macro=true";
import { default as editIvemQh7b5vMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as indexkaOteWWv5YMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as addjd2Stqn40ZMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexNgEa6CPv6zMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/organisations/index.vue?macro=true";
import { default as invitationsnYHAlrZ0IYMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/other/invitations.vue?macro=true";
import { default as logsHgAmsi9AFUMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/other/logs.vue?macro=true";
import { default as indexrLOnjVc6gKMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesIRf43IsdBCMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/ressources.vue?macro=true";
import { default as emailsYksNHcuRbUMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/emails.vue?macro=true";
import { default as messagesRCj6e2NOF6Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/messages.vue?macro=true";
import { default as editQES0FXRRouMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45itemsEnIZBiUgnbMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as add112VQqYbmjMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/rules/add.vue?macro=true";
import { default as indexYpDuhdHBHLMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entrantQ8tVdJ4FRAMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortantEf9keSTNieMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversionsOHEt95qJY6Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45valider5PQv1hkEzvMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimeespoh5uQF0q4Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45validerElwg0wQBxFMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexC0OKx8k1ybMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45cles7maBPhAzrbMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missionsAQPCj49QXxMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/missions.vue?macro=true";
import { default as indexk01aCoZ2UKMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimees88KdLXiEstMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45validerkjA40upWiOMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annulees569QIqYyyzMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participationswvVM9jU7V0Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/participations.vue?macro=true";
import { default as placesknG9TsSDdfMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/places.vue?macro=true";
import { default as moderationsHvLMmL6sehMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/suivis/moderations.vue?macro=true";
import { default as utilisateursnkZRVH7oyPMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as editwRyEpmaakMMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as addEq3q8ZpWo5Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as indexOh5PTngW8LMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignagesVVoNwzGokdMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/temoignages.vue?macro=true";
import { default as edithEuOeXnwpDMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexloi3lyAIV4Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as indexSwkPOID0FOMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/utilisateurs/index.vue?macro=true";
import { default as benevolesZjMU2B4WNSMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as indexZUGlI7QC4nMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsablesjsLRBv5hLpMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civiqueg3iUsHcfSUMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisatione61Kz3OpbWMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexTaIhqq5WY4Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93OkkGkxY8A4Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/departements/[slug].vue?macro=true";
import { default as _91slug_93zKmXBr90raMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45momentLrhW0rUQCFMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/en-ce-moment.vue?macro=true";
import { default as indexVnSmRlLTrxMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/index.vue?macro=true";
import { default as indexMyZBWEQiOjMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/index.vue?macro=true";
import { default as competencesTgRPBVzJhwMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilitesFYplZvJHxKMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferencesvhyiyiNBJeMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profilegm5YhkMxQ7Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as indexrq4lVd6TrRMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/index.vue?macro=true";
import { default as index6SBaeMWmR0Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementOypL9o8W1eMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmationIEzIIO2DkZMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectiviteDYzhu4jxl6Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmationC0lJUOWRASMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45detailsZmwn2roMJ9Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45imagesbDP5uWAPogMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisationGzh6jGpjmLMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profileAR550wdkw6Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_93Qz7BNWE266Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/invitations/[hash].vue?macro=true";
import { default as loginSEAn7zkbuAMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/login.vue?macro=true";
import { default as adminx1JzA2mtprMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/maintenance/admin.vue?macro=true";
import { default as indexVYRniiikgNMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legales75jMonmOV3Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/mentions-legales.vue?macro=true";
import { default as _91id_939uQcrmyKCqMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/messages/[id].vue?macro=true";
import { default as indexP7PBYntarbMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/messages/index.vue?macro=true";
import { default as add1kNWO17mxpMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/messages/modeles/add.vue?macro=true";
import { default as indexebTa2LHerjMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_93VcsF6GSHPsMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as index0xNP8sxA0rMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as index8rIgqcj3DqMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/missions-benevolat/index.vue?macro=true";
import { default as notificationsxi7Q5WuDAiMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/notifications.vue?macro=true";
import { default as _91slug_93yB7utqASJcMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/organisations/[slug].vue?macro=true";
import { default as indexvZt2rbMWZXMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/organisations/index.vue?macro=true";
import { default as _91token_93hlmlgm7Z1aMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/password-reset/[token].vue?macro=true";
import { default as indexGBrIxsAStAMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45siterRvNVzONrFMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialiteUoALEmfRrsMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/politique-de-confidentialite.vue?macro=true";
import { default as indexAoNRnp5LuFMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/alertes/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementzAlKXZ27IfMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editGw2GLFTDgzMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/edit.vue?macro=true";
import { default as favoristWKHDzMGXnMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/favoris.vue?macro=true";
import { default as indexBVbgVAJ0P3Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/index.vue?macro=true";
import { default as missionsWaWBBOVBAoMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/missions.vue?macro=true";
import { default as notificationskRrJBLaR5xMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/notifications.vue?macro=true";
import { default as preferencesZ8PB1v6JLPMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/preferences.vue?macro=true";
import { default as settingsb1DUQs2QX7Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensemble6N2EQ5emJ4Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generiqueWVzSqdHu55Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/generique.vue?macro=true";
import { default as mgenAoNce5uyJKMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planeteVLdIIeE1lTMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendre9cJveVFVjbMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_93cVsHMsR8wiMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/reseaux/[slug].vue?macro=true";
import { default as index0sGS4UqBakMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/index.vue?macro=true";
import { default as missionsfV7Cd2kzViMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/missions.vue?macro=true";
import { default as organisationspNWzm5irHiMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/organisations.vue?macro=true";
import { default as participationst3cY5pmwE3Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/participations.vue?macro=true";
import { default as places0twiFCm8cPMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/places.vue?macro=true";
import { default as utilisateursRcrC5flRaiMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/utilisateurs.vue?macro=true";
import { default as google_45api_45indexingInxKOEJbOkMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/google-api-indexing.vue?macro=true";
import { default as search_45archived_45usersSIHk4g7YJRMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisationOgyCwvxpL3Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45rolerqXGMAvd1OMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45linktUn9mHEHm5Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationsefZhYlE0oWMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoiresUNXhFfQ6g0Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as indexJmJGOGcn36Meta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/index.vue?macro=true";
import { default as activity_45logscehiRIw7PKMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actionsPJ7rg7gMaNMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdatedAWsWs2GgZgMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treatedqfwhULkohoMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_93XSCxpu2zyEMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/temoignages/[token].vue?macro=true";
import { default as indexhTKQlGoNWIMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/territoires/index.vue?macro=true";
import { default as _91slug_93fK4k4AvMDBMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/pages/villes/[slug].vue?macro=true";
import { default as component_45stubnVOifHForVMeta } from "/build/454a6611-c723-483c-b959-363e6be35d78/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnVOifHForV } from "/build/454a6611-c723-483c-b959-363e6be35d78/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accessibilite",
    path: "/accessibilite",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "activites-slug",
    path: "/activites/:slug()",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/activites/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id-edit",
    path: "/admin/contenus/activites/:id()/edit",
    meta: editas5J2ppMDNMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/activites/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-id",
    path: "/admin/contenus/activites/:id()",
    meta: indexjrkzdnArYLMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/activites/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites-add",
    path: "/admin/contenus/activites/add",
    meta: addG623JslFyBMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/activites/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-activites",
    path: "/admin/contenus/activites",
    meta: indexbghOv0yM2oMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/activites/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-id-edit",
    path: "/admin/contenus/domaines/:id()/edit",
    meta: edit6sUC1urzxQMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/domaines/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines-add",
    path: "/admin/contenus/domaines/add",
    meta: addVLxSbQuM0oMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/domaines/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-domaines",
    path: "/admin/contenus/domaines",
    meta: indexQ3PJXG59muMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/domaines/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-id-edit",
    path: "/admin/contenus/modeles-mission/:id()/edit",
    meta: editOTuLbGwN3HMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/modeles-mission/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission-add",
    path: "/admin/contenus/modeles-mission/add",
    meta: addJYz9jSnKUCMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/modeles-mission/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-modeles-mission",
    path: "/admin/contenus/modeles-mission",
    meta: indexNHO4uf0qkFMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/modeles-mission/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-posts",
    path: "/admin/contenus/posts",
    meta: postsSR5cM7n7H6Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/posts.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id-edit",
    path: "/admin/contenus/reseaux/:id()/edit",
    meta: edit4tGCpGdy3iMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/reseaux/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-id",
    path: "/admin/contenus/reseaux/:id()",
    meta: indexpf8Gmw4L8UMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/reseaux/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux-add",
    path: "/admin/contenus/reseaux/add",
    meta: addaSagh7uKDgMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/reseaux/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-reseaux",
    path: "/admin/contenus/reseaux",
    meta: indexokO1sW5wbGMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/reseaux/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-id-edit",
    path: "/admin/contenus/ressources/:id()/edit",
    meta: editclwZOpSwOhMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/ressources/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources-add",
    path: "/admin/contenus/ressources/add",
    meta: addC3oCO0Lq2LMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/ressources/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-ressources",
    path: "/admin/contenus/ressources",
    meta: indexhSqaypmSZyMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id-edit",
    path: "/admin/contenus/territoires/:id()/edit",
    meta: editc3NL5rzSMuMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/territoires/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-id",
    path: "/admin/contenus/territoires/:id()",
    meta: indexL6ewVOexWlMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/territoires/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires-add",
    path: "/admin/contenus/territoires/add",
    meta: addKsWG03rmDzMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/territoires/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-territoires",
    path: "/admin/contenus/territoires",
    meta: indexvynx36JmYMMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials-id-edit",
    path: "/admin/contenus/testimonials/:id()/edit",
    meta: edit1wZYhfshI5Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/testimonials/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-contenus-testimonials",
    path: "/admin/contenus/testimonials",
    meta: indexSCNbkynNtzMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/contenus/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexw2hFeKb8s6Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles-informations",
    path: "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informationsODXAU75pzCMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/benevoles-informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-benevoles",
    path: "/admin/missions/:id()/benevoles",
    meta: benevolesMXkbIS9QkDMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-dates",
    path: "/admin/missions/:id()/dates",
    meta: datesjIVDI1wbkxMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/dates.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id",
    path: "/admin/missions/:id()",
    meta: index0PKDBasV9xMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-informations",
    path: "/admin/missions/:id()/informations",
    meta: informationsRlFvwPYaR0Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-lieux",
    path: "/admin/missions/:id()/lieux",
    meta: lieuxkxQgxMkNEXMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/lieux.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-responsables",
    path: "/admin/missions/:id()/responsables",
    meta: responsablesPeuTv8TyjZMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/responsables.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-title",
    path: "/admin/missions/:id()/title",
    meta: title56OSo03WEWMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/title.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles",
    path: "/admin/missions/:id()/trouver-des-benevoles",
    meta: indexZNPI679C1iMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/trouver-des-benevoles/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-trouver-des-benevoles-redirect",
    path: "/admin/missions/:id()/trouver-des-benevoles/redirect",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/trouver-des-benevoles/redirect.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions-id-visuel",
    path: "/admin/missions/:id()/visuel",
    meta: visuelkR2pCIMdeTMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/[id]/visuel.vue").then(m => m.default || m)
  },
  {
    name: "admin-missions",
    path: "/admin/missions",
    meta: indexBnpDWbS4IMMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/missions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-notes",
    path: "/admin/notes",
    meta: notesSRhhGBQd0SMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/notes.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-edit",
    path: "/admin/organisations/:id()/edit",
    meta: editIvemQh7b5vMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id",
    path: "/admin/organisations/:id()",
    meta: indexkaOteWWv5YMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/organisations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations-id-missions-add",
    path: "/admin/organisations/:id()/missions/add",
    meta: addjd2Stqn40ZMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/organisations/[id]/missions/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-organisations",
    path: "/admin/organisations",
    meta: indexNgEa6CPv6zMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-invitations",
    path: "/admin/other/invitations",
    meta: invitationsnYHAlrZ0IYMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/other/invitations.vue").then(m => m.default || m)
  },
  {
    name: "admin-other-logs",
    path: "/admin/other/logs",
    meta: logsHgAmsi9AFUMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/other/logs.vue").then(m => m.default || m)
  },
  {
    name: "admin-participations",
    path: "/admin/participations",
    meta: indexrLOnjVc6gKMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/participations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-ressources",
    path: "/admin/ressources",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/ressources.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-emails",
    path: "/admin/settings/emails",
    meta: emailsYksNHcuRbUMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-messages",
    path: "/admin/settings/messages",
    meta: messagesRCj6e2NOF6Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/messages.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-edit",
    path: "/admin/settings/rules/:id()/edit",
    meta: editQES0FXRRouMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-id-pending-items",
    path: "/admin/settings/rules/:id()/pending-items",
    meta: pending_45itemsEnIZBiUgnbMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/rules/[id]/pending-items.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules-add",
    path: "/admin/settings/rules/add",
    meta: add112VQqYbmjMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/rules/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-rules",
    path: "/admin/settings/rules",
    meta: indexYpDuhdHBHLMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/settings/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-entrant",
    path: "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entrantQ8tVdJ4FRAMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/api-engagement/trafic-entrant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-api-engagement-trafic-sortant",
    path: "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortantEf9keSTNieMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/api-engagement/trafic-sortant.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-conversions",
    path: "/admin/statistics/conversions",
    meta: conversionsOHEt95qJY6Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/conversions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-a-valider",
    path: "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45valider5PQv1hkEzvMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/departements/missions-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-missions-perimees",
    path: "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimeespoh5uQF0q4Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/departements/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-departements-organisations-a-valider",
    path: "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45validerElwg0wQBxFMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/departements/organisations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics",
    path: "/admin/statistics",
    meta: indexC0OKx8k1ybMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-indicateurs-cles",
    path: "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45cles7maBPhAzrbMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/indicateurs-cles.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-missions",
    path: "/admin/statistics/missions",
    meta: missionsAQPCj49QXxMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/missions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations",
    path: "/admin/statistics/organisations",
    meta: indexk01aCoZ2UKMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-missions-perimees",
    path: "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimees88KdLXiEstMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/organisations/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-a-valider",
    path: "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45validerkjA40upWiOMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/organisations/participations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-organisations-participations-refusees-annulees",
    path: "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annulees569QIqYyyzMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/organisations/participations-refusees-annulees.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-participations",
    path: "/admin/statistics/participations",
    meta: participationswvVM9jU7V0Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/participations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-places",
    path: "/admin/statistics/places",
    meta: placesknG9TsSDdfMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/places.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-suivis-moderations",
    path: "/admin/statistics/suivis/moderations",
    meta: moderationsHvLMmL6sehMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/suivis/moderations.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics-utilisateurs",
    path: "/admin/statistics/utilisateurs",
    meta: utilisateursnkZRVH7oyPMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/statistics/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-id-edit",
    path: "/admin/taxonomies/:slug()/:id()/edit",
    meta: editwRyEpmaakMMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/taxonomies/[slug]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug-add",
    path: "/admin/taxonomies/:slug()/add",
    meta: addEq3q8ZpWo5Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/taxonomies/[slug]/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-taxonomies-slug",
    path: "/admin/taxonomies/:slug()",
    meta: indexOh5PTngW8LMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/taxonomies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-temoignages",
    path: "/admin/temoignages",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/temoignages.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id-edit",
    path: "/admin/utilisateurs/:id()/edit",
    meta: edithEuOeXnwpDMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/utilisateurs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs-id",
    path: "/admin/utilisateurs/:id()",
    meta: indexloi3lyAIV4Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/utilisateurs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-utilisateurs",
    path: "/admin/utilisateurs",
    meta: indexSwkPOID0FOMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/admin/utilisateurs/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-benevoles",
    path: "/charte-bon-fonctionnement/benevoles",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/charte-bon-fonctionnement/benevoles.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement",
    path: "/charte-bon-fonctionnement",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/charte-bon-fonctionnement/index.vue").then(m => m.default || m)
  },
  {
    name: "charte-bon-fonctionnement-responsables",
    path: "/charte-bon-fonctionnement/responsables",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/charte-bon-fonctionnement/responsables.vue").then(m => m.default || m)
  },
  {
    name: "charte-reserve-civique",
    path: "/charte-reserve-civique",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/charte-reserve-civique.vue").then(m => m.default || m)
  },
  {
    name: "conditions-generales-d-utilisation",
    path: "/conditions-generales-d-utilisation",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/conditions-generales-d-utilisation.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexTaIhqq5WY4Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "departements-slug",
    path: "/departements/:slug()",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/departements/[slug].vue").then(m => m.default || m)
  },
  {
    name: "domaines-action-slug",
    path: "/domaines-action/:slug()",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/domaines-action/[slug].vue").then(m => m.default || m)
  },
  {
    name: "en-ce-moment",
    path: "/en-ce-moment",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/en-ce-moment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole",
    path: "/inscription/benevole",
    meta: indexMyZBWEQiOjMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-competences",
    path: "/inscription/benevole/step/competences",
    meta: competencesTgRPBVzJhwMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/step/competences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-disponibilites",
    path: "/inscription/benevole/step/disponibilites",
    meta: disponibilitesFYplZvJHxKMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/step/disponibilites.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-preferences",
    path: "/inscription/benevole/step/preferences",
    meta: preferencesvhyiyiNBJeMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/step/preferences.vue").then(m => m.default || m)
  },
  {
    name: "inscription-benevole-step-profile",
    path: "/inscription/benevole/step/profile",
    meta: profilegm5YhkMxQ7Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/benevole/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    meta: indexrq4lVd6TrRMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable",
    path: "/inscription/responsable",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-charte-bon-fonctionnement",
    path: "/inscription/responsable/step/charte-bon-fonctionnement",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite-confirmation",
    path: "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmationIEzIIO2DkZMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/collectivite-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-collectivite",
    path: "/inscription/responsable/step/collectivite",
    meta: collectiviteDYzhu4jxl6Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/collectivite.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-confirmation",
    path: "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmationC0lJUOWRASMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/organisation-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-details",
    path: "/inscription/responsable/step/organisation-details",
    meta: organisation_45detailsZmwn2roMJ9Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/organisation-details.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation-images",
    path: "/inscription/responsable/step/organisation-images",
    meta: organisation_45imagesbDP5uWAPogMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/organisation-images.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-organisation",
    path: "/inscription/responsable/step/organisation",
    meta: organisationGzh6jGpjmLMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/organisation.vue").then(m => m.default || m)
  },
  {
    name: "inscription-responsable-step-profile",
    path: "/inscription/responsable/step/profile",
    meta: profileAR550wdkw6Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/inscription/responsable/step/profile.vue").then(m => m.default || m)
  },
  {
    name: "invitations-hash",
    path: "/invitations/:hash()",
    meta: _91hash_93Qz7BNWE266Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/invitations/[hash].vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginSEAn7zkbuAMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance-admin",
    path: "/maintenance/admin",
    meta: adminx1JzA2mtprMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/maintenance/admin.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: indexVYRniiikgNMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: _91id_939uQcrmyKCqMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: indexP7PBYntarbMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles-add",
    path: "/messages/modeles/add",
    meta: add1kNWO17mxpMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/messages/modeles/add.vue").then(m => m.default || m)
  },
  {
    name: "messages-modeles",
    path: "/messages/modeles",
    meta: indexebTa2LHerjMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/messages/modeles/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id-slug",
    path: "/missions-benevolat/:id()/:slug()",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/missions-benevolat/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat-id",
    path: "/missions-benevolat/:id()",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/missions-benevolat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "missions-benevolat",
    path: "/missions-benevolat",
    meta: index8rIgqcj3DqMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/missions-benevolat/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsxi7Q5WuDAiMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "organisations-slug",
    path: "/organisations/:slug()",
    meta: _91slug_93yB7utqASJcMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/organisations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "organisations",
    path: "/organisations",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93hlmlgm7Z1aMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexGBrIxsAStAMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-du-site",
    path: "/plan-du-site",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/plan-du-site.vue").then(m => m.default || m)
  },
  {
    name: "politique-de-confidentialite",
    path: "/politique-de-confidentialite",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "profile-alertes",
    path: "/profile/alertes",
    meta: indexAoNRnp5LuFMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/alertes/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-charte-bon-fonctionnement",
    path: "/profile/charte-bon-fonctionnement",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: editGw2GLFTDgzMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "profile-favoris",
    path: "/profile/favoris",
    meta: favoristWKHDzMGXnMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/favoris.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexBVbgVAJ0P3Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-missions",
    path: "/profile/missions",
    meta: missionsWaWBBOVBAoMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/missions.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationskRrJBLaR5xMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "profile-preferences",
    path: "/profile/preferences",
    meta: preferencesZ8PB1v6JLPMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/preferences.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsb1DUQs2QX7Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "quiz-decembre-ensemble",
    path: "/quiz/decembre-ensemble",
    meta: decembre_45ensemble6N2EQ5emJ4Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/decembre-ensemble.vue").then(m => m.default || m)
  },
  {
    name: "quiz-generique",
    path: "/quiz/generique",
    meta: generiqueWVzSqdHu55Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/generique.vue").then(m => m.default || m)
  },
  {
    name: "quiz-mgen",
    path: "/quiz/mgen",
    meta: mgenAoNce5uyJKMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/mgen.vue").then(m => m.default || m)
  },
  {
    name: "quiz-printemps-pour-la-planete",
    path: "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planeteVLdIIeE1lTMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/printemps-pour-la-planete.vue").then(m => m.default || m)
  },
  {
    name: "quiz-septembre-pour-apprendre",
    path: "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendre9cJveVFVjbMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/quiz/septembre-pour-apprendre.vue").then(m => m.default || m)
  },
  {
    name: "reseaux-slug",
    path: "/reseaux/:slug()",
    meta: _91slug_93cVsHMsR8wiMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/reseaux/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stats",
    path: "/stats",
    meta: index0sGS4UqBakMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: "stats-missions",
    path: "/stats/missions",
    meta: missionsfV7Cd2kzViMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/missions.vue").then(m => m.default || m)
  },
  {
    name: "stats-organisations",
    path: "/stats/organisations",
    meta: organisationspNWzm5irHiMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/organisations.vue").then(m => m.default || m)
  },
  {
    name: "stats-participations",
    path: "/stats/participations",
    meta: participationst3cY5pmwE3Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/participations.vue").then(m => m.default || m)
  },
  {
    name: "stats-places",
    path: "/stats/places",
    meta: places0twiFCm8cPMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/places.vue").then(m => m.default || m)
  },
  {
    name: "stats-utilisateurs",
    path: "/stats/utilisateurs",
    meta: utilisateursRcrC5flRaiMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/stats/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-google-api-indexing",
    path: "/support/actions/google-api-indexing",
    meta: google_45api_45indexingInxKOEJbOkMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/google-api-indexing.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-search-archived-users",
    path: "/support/actions/search-archived-users",
    meta: search_45archived_45usersSIHk4g7YJRMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/search-archived-users.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-transfert-organisation",
    path: "/support/actions/transfert-organisation",
    meta: transfert_45organisationOgyCwvxpL3Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/transfert-organisation.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-context-role",
    path: "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45rolerqXGMAvd1OMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/user-reset-context-role.vue").then(m => m.default || m)
  },
  {
    name: "support-actions-user-reset-password-link",
    path: "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45linktUn9mHEHm5Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/actions/user-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-organisations",
    path: "/support/contents/doublons-organisations",
    meta: doublons_45organisationsefZhYlE0oWMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/contents/doublons-organisations.vue").then(m => m.default || m)
  },
  {
    name: "support-contents-doublons-territoires",
    path: "/support/contents/doublons-territoires",
    meta: doublons_45territoiresUNXhFfQ6g0Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/contents/doublons-territoires.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexJmJGOGcn36Meta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-activity-logs",
    path: "/support/referents/activity-logs",
    meta: activity_45logscehiRIw7PKMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/referents/activity-logs.vue").then(m => m.default || m)
  },
  {
    name: "support-referents-waiting-actions",
    path: "/support/referents/waiting-actions",
    meta: waiting_45actionsPJ7rg7gMaNMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/referents/waiting-actions.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-missions-outdated",
    path: "/support/responsables/missions-outdated",
    meta: missions_45outdatedAWsWs2GgZgMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/responsables/missions-outdated.vue").then(m => m.default || m)
  },
  {
    name: "support-responsables-participations-to-be-treated",
    path: "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treatedqfwhULkohoMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/support/responsables/participations-to-be-treated.vue").then(m => m.default || m)
  },
  {
    name: "temoignages-token",
    path: "/temoignages/:token()",
    meta: _91token_93XSCxpu2zyEMeta || {},
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/temoignages/[token].vue").then(m => m.default || m)
  },
  {
    name: "territoires",
    path: "/territoires",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: "villes-slug",
    path: "/villes/:slug()",
    component: () => import("/build/454a6611-c723-483c-b959-363e6be35d78/pages/villes/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/register/volontaire/:pathMatch(.*)",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/register/responsable/:pathMatch(.*)",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/user/settings",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/user/:pathMatch(.*)",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/dashboard/structure/:pathMatch(.*)",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/dashboard/mission/:pathMatch(.*)",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/dashboard/participation/:pathMatch(.*)",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/dashboard/profile/:pathMatch(.*)",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/dashboard/reseaux/:pathMatch(.*)",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/inscription/organisation",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/statistiques",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/activites/accompagnement-aux-activites-sportives",
    component: component_45stubnVOifHForV
  },
  {
    name: component_45stubnVOifHForVMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnVOifHForV
  }
]